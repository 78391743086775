<template>
    <div>
        <div class="secondHeader">
            <div class="inputGroup" >        
                <el-input placeholder="用户姓名"
                          v-model="searchData.userName"
                          prefix-icon="el-icon-user">
                </el-input>
                <el-cascader placeholder="请选择具体队伍"
                            v-model="searchData.department"
                            :options="departments"
                            :props="{expandTrigger:'hover'}"
                            clearable
                            :disabled="cascader_disabled"
                            >
                </el-cascader>
                <el-date-picker v-model="searchData.datetimeRange"
                        type="datetimerange"
                        range-separator="-"
                        start-placeholder="起始时间"
                        end-placeholder="终止时间"
                        value-format="yyyy-MM-dd hh:mm:ss">
                </el-date-picker>
                <el-button type="text" @click="handleSearch">搜索</el-button>
            </div>
            <div class="buttonGroup">
                <el-button @click="exportAll">导出全部数据</el-button>
                <el-button @click="exportResult(searchData)">导出查询结果</el-button>
            </div> 
        </div>
        <div>
            <el-table   :data="showPage.slice((currentPage-1)*10,currentPage*10)"
                        style="width:100%"
                        @sort-change="handleSort"
                        @filter-change="handleFilter"
                        v-loading="table_loading"
            >
                <!-- <el-table-column prop="userId" label="编号" width="70"></el-table-column> -->
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="phone" label="联系电话">
                    <!-- <template slot-scope="scope">
                        <span>{{scope.row.areaName}}-{{scope.row.departmentName}}</span>
                    </template> -->
                </el-table-column>
                <el-table-column label="队伍">
                    <template slot-scope="scope">
                        <span>{{scope.row.areaName}}-{{scope.row.departmentName}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="用户等级"
                                 :filters="filter"
                                 >
                    <template slot-scope="scope">
                        <el-tag :type="global.COMMENTOR_LEVEL[scope.row.level].type">{{global.COMMENTOR_LEVEL[scope.row.level].label}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="taskCount" label="接受任务总数"
                                 sortable="custom">
                </el-table-column>
                <el-table-column prop="completeCount" label="已完成任务数"
                                 sortable="custom"
                                 >
                </el-table-column>
                <el-table-column prop="articleCount" label="已完成投稿数"
                                 sortable="custom">
                </el-table-column>
                <el-table-column prop="completeRate" label="任务完成率"
                                 sortable="custom">
                                 <template slot-scope="scope">
                                        <span>{{(scope.row.completeRate*100).toFixed(2)}}%</span>
                                    </template>
                </el-table-column>               
                
             </el-table>
             <el-pagination layout="prev,pager,next"
                            :page-size.sync="global.PAGE_SIZE"
                            :total="total"
                            :current-page.sync="currentPage"
                            >
             </el-pagination>
        </div>
    </div>
</template>

<script>
import '../../../assets/css/secondHeader.css'
export default {
  name:'KPITable',
  data(){
   return {
       searchData:{
           userName:"",
           department:[],
           datetimeRange:[],
           level:"",
       },
       cascader_disabled:false,
       departments:[],
       table_loading:false,
       KPI:[],
       showPage:[],
       filter:[],
       currentPage:1,
       total:null,
   }
  },
  mounted(){
      //获取数据
        this.departments = JSON.parse(sessionStorage.getItem('departments'))
        let form = {
            departmentId:''
        }
        let userInfo = this.global.getUserInfo()
        if(userInfo.sysRoleCode==1){
            form.departmentId = userInfo.departmentId
            this.searchData.department = [userInfo.areaName,userInfo.departmentId]
            console.log(this.searchData.department)
            this.cascader_disabled = true
        }  
        else
            form.departmentId = 0
        for(let item in this.global.COMMENTOR_LEVEL){
            this.filter.push({
                text:this.global.COMMENTOR_LEVEL[item].label,
                value:this.global.COMMENTOR_LEVEL[item].info
            })
        }
        this.search(form)
  },
  methods:{
    handleSearch(){
        let form = {
            departmentId:this.searchData.department.length>0?this.searchData.department[1]:0,
            userName:this.searchData.userName,
            startTime:this.searchData.datetimeRange[0],
            endTime:this.searchData.datetimeRange[1]
        }
        console.log(form)
        this.search(form)
    },
    search(form){
        this.table_loading = true
        this.$axios.post('/api/user/task/statistics',form).then(res=>{
            console.log(res)
            this.KPI = res.data.data
            this.showPage = this.KPI.slice()
            this.total = this.KPI.length
            this.currentPage = 1
        })
        // .catch(err=>{
        //     this.$message({
        //         message:err,
        //         type:'error'
        //     })
        //     this.table_loading = false
        // })
        .finally(()=>{
            this.table_loading = false
            console.log(this.KPI)
        })
    },
    handleSort(val){
        this.sortByProp(val.prop,val.order)
    },
    sortByProp(prop,order){
        this.currentPage = 1
        if(order==='descending')
            this.showPage.sort((val1,val2)=>val1[prop]-val2[prop])
        else
            this.showPage.sort((val1,val2)=>val2[prop]-val1[prop])
    },
    //仅限一个筛选条件
    handleFilter(filters){
        this.table_loading = true
        let pr = new Promise((resolve)=>{
            let tmp = []
            for(let item in filters)
                tmp = filters[item]
            if(tmp.length==0){
                this.showPage = this.KPI
                this.currentPage = 1
                this.total = this.showPage.length
            }else{
                let res = []
                for(let item of this.KPI){
                    for(let item2 of tmp){
                        if(item.level==item2)
                            res.push(item)
                    }
                }
                console.log('res',res)
                this.showPage = res
                this.currentPage = 1
                this.total = this.showPage.length
            }
            resolve()
        })
        pr.then(()=>{
            console.log(1)
            this.table_loading = false
        })
        
    },
    exportAll(){
        let userInfo = this.global.getUserInfo()
        let form = {
            department:userInfo.sysRoleCode==2?0:userInfo.departmentId
        }
        console.log(form)
        this.exportResult(form)
    },
    exportResult(queryForm){
        this.$axios.post('/api/user/task/statistics/export_with_query',queryForm,{responseType:'blob'})
                    .then(res=>{
                        console.log(res)
                        let pr = new Promise((resolve)=>{
                            let blob = new Blob([res.data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = e => {
                                const a = document.createElement('a');
                                a.href = e.target.result;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                                resolve()
                            }
                        })
                        pr.then(()=>{
                            this.$message({
                            message:"导出成功",
                            type:"success"
                            }) 
                        })
                        // .catch(err=>{
                        // this.$message({
                        //     message:err,
                        //     type:"error"
                        // })
                        // })
                    }
                )}

  },
  
}
</script>


<style src='../../../assets/css/secondHeader.css' scoped>

</style>
<style src='../../../assets/css/table.css' scoped>

</style>
<style src='../../../assets/css/dialog.css' scoped>

</style>
<style scoped>
   /* .el-input{
      width:150px;
  }  */
  .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
      width:350px;
  }
</style>

